import React, { useState } from "react";
import { Steps } from "primereact/steps";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import MyProfile from "../my-profile/MyProfile";
import MyQualificationList from "../my-qualification/MyQualificationList";
import MyExperienceList from "../my-experience/MyExperienceList";
import MyDocumentList from "../my-documents/MyDocumentList";
import { useNavigate } from "react-router-dom";
import OtherDetails from "../other-details/OtherDetails";

const Stepper = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const navigate = useNavigate();
    const steps = [
        { label: "My Profile"},
        { label: "My Qualification"},
        { label: "My Experience" },
        { label: "Other Details" },
        { label: "My Documents" },
    ];

    const onNext = () => {
        setActiveIndex((prevIndex) => prevIndex + 1);
    };

    const onBack = () => {
        setActiveIndex((prevIndex) => prevIndex - 1);
    };

    const handleFinish = () => {
        if(window.confirm("Are you sure, you want to proceed? Please apply for the post once redirected back to Advertisements page.")) {
            navigate("/advertisements");
        }
    }
    

    const renderFooter = () => {
        return (
            <div className="flex justify-content-end">
                {activeIndex !== 0 && (
                    <Button
                        label="Back"
                        icon="pi pi-chevron-left"
                        onClick={onBack}
                        className="p-button-text"
                    />
                )}
                {activeIndex !== steps.length - 1 && (
                    <Button
                        label="Next"
                        icon="pi pi-chevron-right"
                        onClick={onNext}
                    />
                )}
                {activeIndex === steps.length - 1 && (
                    <Button onClick={handleFinish} label="Finish" icon="pi pi-check" />
                )}
            </div>
        );
    };

    return (
        <div className="stepper-demo">
            <div className="">
                {/* {isLoading && <Loading />} */}
                {/* <Toast ref={toast} className="ToastMessage" /> */}
                <div className="">
                    <Card
                        title={steps[activeIndex].label}
                        // subTitle="You can only register if you are citizen of India"
                        footer={renderFooter()}
                    >
                        <Steps model={steps} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />
                        {activeIndex === 0 && <MyProfile />}
                        {activeIndex === 1 && <MyQualificationList />}
                        {activeIndex === 2 && <MyExperienceList />}
                        {activeIndex === 3 && <OtherDetails />}
                        {activeIndex === 4 && <MyDocumentList />}
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Stepper;
