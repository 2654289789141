
import { Fragment } from 'react';

const ApplicationHeader = () => {

    return(
        <Fragment>
            <div className="flex mb-4 justify-content-between">
                <img
                    className="ml-4"
                    src={`/ncralogo.png`}
                    alt="Profile"
                    style={{
                        width: 'auto',
                        height: '100px'
                    }}
                />
                <div className="">
                    <h1 className="mb-0">National Centre for Radio Astrophysics</h1>
                    <h3 className="mb-0 mt-0">TATA INSTITUTE OF FUNDAMENTAL RESEARCH</h3>
                    <div>NCRA.TIFR, Post Bag 3, Ganeshkhind, S.P. Pune University Campus, Pune - 411007, INDIA</div>
                    <div>Tel: +91 25719000/9111; URL: http://www.ncra.tifr.res.in</div>
                </div>
                
            </div>
            <div className="flex justify-content-center mb-4">
                <h2 className="mb-0 mt-0">BIO-DATA</h2>
            </div>
            <hr />
        </Fragment>
    );
};

export default ApplicationHeader;
