import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const ApplicationDocuments = ({documentList, isLoading}) => {

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const dateTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field] ? moment(data[props.field]).format('DD-MM-YYYY') : '-'}
            </>
        );
    };

    const pdfTemplate = (data, header, pathName) => {
        return (
            <>
                <span className="p-column-title">{header}</span>
                {data?.fileName ? (
                    <a target="_blank" href={`${process.env.REACT_APP_API_BASE_URL}/${pathName}/${data?.fileName}`}>
                        View File
                    </a>
                ) : (
                    '-'
                )}
            </>
        );
    };
    
    return (
        <DataTable
            value={documentList}
            scrollable
            scrollHeight="600px"
            scrollDirection="both"
            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
            rows={10}
            dataKey="id"
            paginator={false}
            rowHover
            // globalFilter={globalFilter}
            emptyMessage="No list found."
            loading={isLoading}
            // header={tableHeader}
        >
            <Column
                style={{
                    flexGrow: 1,
                    flexBasis: '100px'
                }}
                field="documentName"
                sortable
                body={bodyTemplate}
            ></Column>
            <Column
                style={{
                    flexGrow: 1,
                    flexBasis: '100px'
                }}
                field="fileName"
                header="File Name"
                sortable
                body={(data) => pdfTemplate(data, 'File Name', 'fetchDocumentsPDF')}
            ></Column>
            {/* <Column
            style={{ flexGrow: 1, flexBasis: "50px" }}
            field=""
            header="Actions"
            body={actionBodyTemplate}
        ></Column> */}
        </DataTable>
    );
}

export default ApplicationDocuments;