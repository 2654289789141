import moment from 'moment';

const AdvertisementDetails = ({ applicationInformation }) => {
    return (
        <div className="grid">
            <div className="col-8">
                <div className="grid">
                    <div className="col-5">
                        <label>Application Number</label>
                    </div>
                    <div className="col-7">
                        <label>
                            <strong>{`${applicationInformation?.applicationNumberPre}${applicationInformation?.applicationNumber}`}</strong>
                        </label>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-5">
                        <label>Form Submitted On</label>
                    </div>
                    <div className="col-7">
                        <label>
                            <strong>{`${moment(applicationInformation?.form_submitted_on).format('DD-MM-YYYY')}`}</strong>
                        </label>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-5">
                        <label>Advertisement No. & Date</label>
                    </div>
                    <div className="col-7">
                        <label>
                            <strong>{`${applicationInformation?.advertisement?.advertisement_number} - ${moment(applicationInformation?.advertisement?.advertisement_date).format('DD-MM-YYYY')}`}</strong>
                        </label>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-5">
                        <label>Application for the Post</label>
                    </div>
                    <div className="col-7">
                        <label>
                            <strong>{`${applicationInformation?.vacancy?.vacancy_name}`}</strong>
                        </label>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-5">
                        <label>Sr. No. of the Post</label>
                    </div>
                    <div className="col-7">
                        <label>
                            <strong>{`${applicationInformation?.vacancy?.post_srno}`}</strong>
                        </label>
                    </div>
                </div>
                <div className="grid">
                    <div className="col-5">
                        <label>HQ</label>
                    </div>
                    <div className="col-7">
                        <label>
                            <strong>{`${applicationInformation?.vacancy?.post_station}`}</strong>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-4">
                {applicationInformation?.user?.profileImagePath && (
                    <img
                        className="ml-4"
                        src={`${process.env.REACT_APP_API_BASE_URL}/fetchImages/${applicationInformation?.user?.profileImagePath}`}
                        alt="Profile"
                        style={{
                            width: 'auto',
                            height: '100px'
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default AdvertisementDetails;
