import RestClient from "RestClient";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const restClient = new RestClient();

export const emailValidation = async (payload) => {
    const result = await restClient.get(`${BASE_URL}/users/validateEmail`,payload);
    return result;
}

export const doLogin = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/users/authenticate`, payload);
    const { data, status } = result;
    if (status) {
        return data;
    } else {
        return result;
    }

}

export const doRegister = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/users/register`,payload);
    return result;
}

export const checkIfLoggedIn = () => {
    const result = sessionStorage.getItem("userInfo");
    if (result) {
        return result;
    }
    return false;
};

export const doLogout = () => {
    sessionStorage.clear();
    return true;
};

export const requestResetPassword = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/users/requestPasswordReset`,payload);
    return result;
}

export const doResetPassword = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/users/doPasswordReset`,payload);
    return result;
}

export const fetchCategories = async () => {
    const result = await restClient.get(`${BASE_URL}/categories`);
    return result;
}


export const fetchPwBDS = async () => {
    const result = await restClient.get(`${BASE_URL}/pwbds`);
    return result;
}

export const fetchStates = async () => {
    const result = await restClient.get(`${BASE_URL}/states`);
    return result;
}