import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import PersonalInformation from './components/PersonalInformation';
import ProfessionalExperience from './components/ProfessionalExperience';
import Qualification from './components/Qualification';
import ApplicationDocuments from './components/ApplicationDocuments';
import { useReactToPrint } from 'react-to-print';
import OtherDetails from './components/OtherDetails';
import ApplicationHeader from './components/ApplicationHeader';
import AdvertisementDetails from './components/AdvertismentDetails';
import { Loading } from 'app/components';

export const ComponentToPrint = React.forwardRef((props, ref) => {
    const { applicationInformation, personalInformation, documentList, qualificationList, experienceList, isLoading } = props;
    return (
        <div ref={ref}>
            <style>
                {`
               @media print {
                @page { size: A4; }
              }
                `}
            </style>

            <ApplicationHeader applicationInformation={applicationInformation} />

            <Accordion multiple activeIndex={[0, 1, 2, 3,4,5]}>
            <AccordionTab header="Advertisment Information">{<AdvertisementDetails applicationInformation={applicationInformation} />}</AccordionTab>
                <AccordionTab header="Personal Information">{<PersonalInformation personalInformation={personalInformation} isLoading={isLoading} />}</AccordionTab>
                <AccordionTab header="Professional Experience">{<ProfessionalExperience experienceList={experienceList} isLoading={isLoading} />}</AccordionTab>
                <AccordionTab header="Qualification">{<Qualification qualificationList={qualificationList} isLoading={isLoading} />}</AccordionTab>
                <AccordionTab header="Other Details">{<OtherDetails qualificationList={qualificationList} isLoading={isLoading} />}</AccordionTab>
                <AccordionTab header="Documents">{<ApplicationDocuments documentList={documentList} isLoading={isLoading} />}</AccordionTab>
            </Accordion>
        </div>
    );
});

const ProfileView = ({ applicationInformation, personalInformation, experienceList, qualificationList, isLoading, documentList }) => {
  
    const [isPrinting, setIsPrinting] = useState(false);
    const modalContent = useRef(null);
    const componentRef = useRef(null);
    const promiseResolveRef = useRef(null);


    const renderPersonalInfomration = () => <PersonalInformation personalInformation={personalInformation} />;

    const renderQualification = () => {
        return <Qualification qualificationList={qualificationList} isLoading={isLoading} />;
    };

    const renderExperienceList = () => {
        return <ProfessionalExperience isLoading={isLoading} experienceList={experienceList} />;
    };

    const renderDocuments = () => {
        return <ApplicationDocuments documentList={documentList} isLoading={isLoading} />;
    };

    const renderAdvertismentInfomration = () => {
        return <AdvertisementDetails applicationInformation={applicationInformation} />;
    };

    const renderApplicationStatus = () => {
        return (
            <Fragment>
                {/* <div className="grid">
                    <div className="col-12 lg:col-6 xl:col-4">
                        <div className="grid">
                            <div className="col-12 lg:col-6 xl:col-4 flex align-items-center">
                                <label>Application Status</label>
                            </div>
                            <div className="col-12 lg:col-6 xl:col-8">
                                <Dropdown style={{ width: '100%' }} value={applicationStatus} onChange={handleChange} options={['Accepted', 'Rejected']} id="applicationStatus" name="applicationStatus" placeholder="Select Application Status" />
                            </div>
                        </div>
                    </div>
                </div>
                {!!applicationStatus && applicationStatus === 'Rejected' && (
                    <div className="grid">
                        <div className="col-12 lg:col-6 xl:col-4">
                            <div className="grid">
                                <div className="col-12 lg:col-6 xl:col-4 flex align-items-center">
                                    <label>Rejection Reason</label>
                                </div>
                                <div className="col-12 lg:col-6 xl:col-8">
                                    <InputText style={{ width: '100%' }} id="applicationRemarks" name="applicationRemarks" type="text" onChange={handleRemarksChange} value={applicationRemarks} placeholder="Please enter rejection reason" />
                                </div>
                            </div>
                        </div>
                    </div>
                )} */}
                <div className="grid">
                    <div className="field col-12 md:col-6 mt-3">
                        {/* <Button type="button" id="save" name="save" label="Save " onClick={handleSubmit} /> */}

                        <Button type="button" id="print" name="print" label="Print " onClick={handlePrint} />
                    </div>
                    {/* <div className="field col-12 md:col-6 mt-3">
                        <Button type="button" id="print" name="print" label="Print " onClick={printModalContent} />
                    </div> */}
                </div>
            </Fragment>
        );
    };

    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            setIsPrinting(false);
        }
    });

    const renderOtherDetails = () => {
        return <OtherDetails personalInformation={personalInformation} />;
    };

    return (
        <div className="card">
            {isLoading && <Loading />}
            {isPrinting && (
                <ComponentToPrint
                    applicationInformation={applicationInformation}
                    experienceList={experienceList}
                    qualificationList={qualificationList}
                    personalInformation={personalInformation}
                    documentList={documentList}
                    isLoading={isLoading}
                    ref={componentRef}
                />
            )}
            <div ref={modalContent}>
                <ApplicationHeader applicationInformation={applicationInformation} />

                <Accordion multiple activeIndex={[0, 1, 2, 3, 4, 5, 6]}>
                    <AccordionTab header="Advertisment Information">{renderAdvertismentInfomration()}</AccordionTab>
                    <AccordionTab header="Personal Information">{renderPersonalInfomration()}</AccordionTab>
                    <AccordionTab header="Professional Experience">{renderExperienceList()}</AccordionTab>
                    <AccordionTab header="Qualification">{renderQualification()}</AccordionTab>
                    <AccordionTab header="Documents">{renderDocuments()}</AccordionTab>
                    <AccordionTab header="Other Details">{renderOtherDetails()}</AccordionTab>
                    <AccordionTab header="Application Status">{renderApplicationStatus()}</AccordionTab>
                </Accordion>
            </div>
        </div>
    );
};

export default ProfileView;
