import { SuccessToastConfig, ErrorToastConfig } from "app/utils/ToastConstants";
import { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "app/utils/utility.service";
import { Loading } from "app/components";
import { getSingleUser, updateUser } from "./my-profile.service";
import {
    getSessionUserInformation,
    getConvertedFileName,
} from "app/utils/utility.service";
import { FileUpload } from "primereact/fileupload";
import { Password } from "primereact/password";
import { Calendar } from "primereact/calendar";
import { fetchCategories, fetchPwBDS, fetchStates } from "../session.service";
import moment from "moment";
import { convertMBToBytes } from "app/utils/utility.service";
import { getMaritalStatusList } from "app/utils/utility.service";
import { getGenderList } from "app/utils/utility.service";
import { getYesNoList } from "app/utils/utility.service";

const MyProfile = () => {
    const [values, setValues] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [fileSelected, setFileSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef();
    const sessionValues = getSessionUserInformation();

    const fileUploadRef = useRef(null);
    const showSuccess = (message) => {
        toast.current.show(SuccessToastConfig(message));
    };
    const [categories, setCategories] = useState([]);
    const [pwbds, setPwbds] = useState([]);
    const [states, setStates] = useState([]);

    const maritalStatusList = getMaritalStatusList();
    const genderList = getGenderList();
    const yesNoList = getYesNoList();
    const fetchCategoryList = async () => {
        const response = await fetchCategories();
        if (response?.status === 200 && response.data.length > 0) {
            const filtercategories = response.data.filter(
                (res) => res?.name?.toLowerCase() !== "all"
            );
            setCategories(filtercategories);
        } else {
            setCategories([]);
        }
    };

    const fetchPwBDList = async () => {
        const response = await fetchPwBDS();
        if (response?.status === 200 && response.data.length > 0) {
            setPwbds(response.data);
        } else {
            setPwbds([]);
        }
    };

    const fetchStateList = async () => {
        const response = await fetchStates();
        if (response?.status === 200 && response.data.length > 0) {
            setStates(response.data);
        } else {
            setStates([]);
        }
    };

    useEffect(() => {
        fetchCategoryList();
        fetchPwBDList();
        fetchStateList();
    }, []);

    const recordId = sessionValues.id;

    const showError = (message) => {
        toast.current.show(ErrorToastConfig(message ? message : "Error"));
    };

    const validateInput = () => {
        if (
            !values.email ||
            !values.firstname ||
            !values.postcode ||
            !values.lastname ||
            !values.mobile_number ||
            !values.category_name ||
            !values.date_of_birth ||
            !values.city ||
            !values.middlename ||
            !values.state ||
            !values.street1 ||
            !values.nationality ||
            !values.marital_status ||
            !values.gender ||
            !values.ex_serviceman ||
            !values.nearest_railway_station ||
            !values.emp_of_govt ||
            !values.aadhar_number ||
            !values.pan_number
        ) {
            return false;
        }

        if (!validateEmail(values.email)) {
            return false;
        }

        if (isNaN(values.postcode)) {
            return false;
        }
        if (isNaN(values.mobile_number)) {
            return false;
        }
        if (isNaN(values.aadhar_number)) {
            return false;
        }

        if (values.aadhar_number.length !== 12) {
            return false;
        }

        if (values.pan_number.length !== 10) {
            return false;
        }

        if (values.pwd && values.pwd === "Yes" && !values.pwd_description) {
            return false;
        }

        // if (!values?.dateOfBirthImage?.name) {
        //     return false;
        // }
        // if (!values?.addressProofImage?.name) {
        //     return false;
        // }
        // if (!values?.aadharProof?.name) {
        //     return false;
        // }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setFormSubmitted(true);
        if (!validateInput()) {
            return false;
        }
        setIsLoading(true);
        if (recordId) {
            if (fileSelected) {
                let formData = new FormData();
                if (values.profileImageName) {
                    const convertedFileName = getConvertedFileName(
                        values?.profileImageName
                    );
                    formData.append("profileImagePath", `${convertedFileName}`);
                    formData.append(
                        "images",
                        values?.profileImage,
                        `${convertedFileName}`
                    );
                }
                delete values?.profileImage;
                delete values?.profileImagePath;
                for (const key of Object.keys(values)) {
                    formData.append(key, `${values[key]}`);
                }
                const record = await updateUser(recordId, formData);
                if (record?.status === false) {
                    setIsLoading(false);

                    setFileSelected(false);
                    showError(record?.message);
                } else {
                    showSuccess("Profile updated successfully");
                    // insert settings value
                    setValues(null);
                    setFileSelected(false);
                    setValues(record?.data);
                    setIsLoading(false);
                }
            } else {
                const record = await updateUser(recordId, values);
                if (record?.status === false) {
                    setIsLoading(false);
                    showError(record?.message);
                } else {
                    showSuccess("Profile updated successfully");
                    // insert settings value
                    setValues(null);
                    setValues(record?.data);
                    setIsLoading(false);
                }
            }
        }
    };

    const myUploader = (event, name) => {
        const size = convertMBToBytes(Number(1));
        if (size <= event.files[0].size) {
            alert("You cannot upload file size more than " + 1 + "MB");
            fileUploadRef?.current.clear();
            setFileSelected(false);
            return;
        }
        setValues({
            ...values,
            [name]: event.files[0],
            [`${name}Name`]: event.files[0].name,
        });
        setFileSelected(true);
    };

    useEffect(() => {
        const fetchUser = async () => {
            setIsLoading(true);
            let record = await getSingleUser(recordId);
            if (!record.pwd || record.pwd === "null") {
                setValues({ ...record, pwd: "No" });
            } else {
                setValues(record);
            }
            setIsLoading(false);
        };
        if (recordId) {
            fetchUser();
        }
    }, []);

    const handleInputChange = async (e) => {
        const { name, value } = e.target;
        if (name === "pwd") {
            setValues({
                ...values,
                [name]: value,
                pwd_description: "",
            });
        } else {
            setValues({
                ...values,
                [name]: value,
            });
        }
    };

    const cardFooter = (
        <span className="ml-3">
            {/* <Link to="/clients" style={{textDecoration:"none"}}> */}

            {/* </Link> */}
            <Button
                // onClick={handleSubmit}
                label={!recordId ? "Create Account" : "Update Account"}
                className="p-button-raised p-button-warning p-button-lg"
            />
        </span>
    );
    return (
        <div className="center-align">
            {isLoading && <Loading />}
            <Toast ref={toast} className="ToastMessage" />
            <div className="RegisterForm">
                <form method="post" onSubmit={handleSubmit}>
                    <Card
                        title="My Profile"
                        // subTitle="You can only register if you are citizen of India"
                        footer={cardFooter}
                    >
                        <div className="grid">
                            <div className="col-12 md:col-4 lg:col content pr-4 pl-4">
                                <div>
                                    <span className="p-float-label">
                                        <InputText
                                            autoFocus
                                            className="vw-input"
                                            autoComplete="off"
                                            required={true}
                                            name="firstname"
                                            value={values?.firstname}
                                            onChange={handleInputChange}
                                        />
                                        <label>First Name *</label>
                                        {formSubmitted &&
                                            !values?.firstname && (
                                                <small className="p-error">
                                                    Please enter first name
                                                </small>
                                            )}
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 md:col-4 lg:col content pl-4 pr-4">
                                <div>
                                    <span className="p-float-label">
                                        <InputText
                                            className="vw-input"
                                            autoComplete="off"
                                            required={true}
                                            name="middlename"
                                            value={values?.middlename}
                                            onChange={handleInputChange}
                                        />
                                        <label>Middle Name *</label>
                                    </span>
                                    {formSubmitted && !values?.middlename && (
                                        <small className="p-error">
                                            Please enter middlename
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 md:col-4 lg:col content pl-4 pr-4">
                                <div>
                                    <span className="p-float-label">
                                        <InputText
                                            className="vw-input"
                                            autoComplete="off"
                                            required={true}
                                            name="lastname"
                                            value={values?.lastname}
                                            onChange={handleInputChange}
                                        />
                                        <label>Last Name *</label>
                                    </span>
                                    {formSubmitted && !values?.lastname && (
                                        <small className="p-error">
                                            Please enter lastname
                                        </small>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12 md:col-4 lg:col content pr-4 pl-4">
                                <div>
                                    <span className="p-float-label">
                                        <Dropdown
                                            className="vw-dropdown"
                                            value={values?.gender}
                                            options={genderList}
                                            required={false}
                                            name="gender"
                                            onChange={handleInputChange}
                                            // placeholder="Select a Country"
                                        />
                                        <label>Gender *</label>
                                    </span>
                                    {formSubmitted && !values?.gender && (
                                        <small className="p-error">
                                            Please select Gender
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 md:col-4 lg:col content pl-4 pr-4">
                                <div>
                                    <span className="p-float-label">
                                        <Dropdown
                                            className="vw-dropdown"
                                            value={values?.ex_serviceman}
                                            options={yesNoList}
                                            required={false}
                                            name="ex_serviceman"
                                            onChange={handleInputChange}
                                            // placeholder="Select a Country"
                                        />
                                        <label>Ex-Serviceman *</label>
                                    </span>
                                    {formSubmitted &&
                                        !values?.ex_serviceman && (
                                            <small className="p-error">
                                                This field is required
                                            </small>
                                        )}
                                </div>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="col-12 md:col-4 lg:col content pr-4 pl-4">
                                <div>
                                    <span className="p-float-label">
                                        <Dropdown
                                            className="vw-dropdown"
                                            value={values?.marital_status}
                                            options={maritalStatusList}
                                            required={false}
                                            name="marital_status"
                                            onChange={handleInputChange}
                                            // placeholder="Select a Country"
                                        />
                                        <label>Marital Status *</label>
                                    </span>
                                    {formSubmitted &&
                                        !values?.marital_status && (
                                            <small className="p-error">
                                                Please select Marital Status
                                            </small>
                                        )}
                                </div>
                            </div>
                            <div className="col-12 md:col-4 lg:col content pl-4 pr-4">
                                <div>
                                    <span className="p-float-label">
                                        <Dropdown
                                            className="vw-dropdown"
                                            value={values?.pwd}
                                            options={yesNoList}
                                            required={false}
                                            name="pwd"
                                            onChange={handleInputChange}
                                            // placeholder="Select a Country"
                                        />
                                        <label>PwBD</label>
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 md:col-4 lg:col content pl-4 pr-4">
                                <div>
                                    <span className="p-float-label">
                                        <Dropdown
                                            className="vw-dropdown"
                                            value={values?.pwd_description}
                                            options={pwbds}
                                            required={false}
                                            name="pwd_description"
                                            onChange={handleInputChange}
                                            optionLabel="name"
                                            optionValue="name"
                                            disabled={
                                                !values?.pwd ||
                                                values?.pwd === "No"
                                            }
                                            // placeholder="Select a Country"
                                        />
                                        <label>PwBD Description </label>
                                    </span>
                                    {formSubmitted &&
                                        values?.pwd === "Yes" &&
                                        !values?.pwd_description && (
                                            <small className="p-error">
                                                This field is required
                                            </small>
                                        )}
                                </div>
                                {/* <div>
                                    
                                    <span className="p-float-label">
                                        <InputText
                                            className="vw-input"
                                            autoComplete="off"
                                            required={false}
                                            disabled={
                                                !values?.pwd ||
                                                values?.pwd === "No"
                                            }
                                            name="pwd_description"
                                            value={values?.pwd_description}
                                            onChange={handleInputChange}
                                        />
                                        <label>PwBD Description *</label>
                                    </span>
                                    {formSubmitted &&
                                        values?.pwd === "Yes" &&
                                        !values?.pwd_description && (
                                            <small className="p-error">
                                                This field is required
                                            </small>
                                        )}
                                </div> */}
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12 md:col-4 lg:col content pr-4 pl-4">
                                <div>
                                    <span className="p-float-label">
                                        <InputText
                                            className="vw-input"
                                            autoComplete="off"
                                            required={true}
                                            name="mobile_number"
                                            value={values?.mobile_number}
                                            onChange={handleInputChange}
                                        />
                                        <label>Mobile No. *</label>
                                    </span>
                                    {formSubmitted &&
                                        !values?.mobile_number && (
                                            <small className="p-error">
                                                Please enter mobile number
                                            </small>
                                        )}
                                    {formSubmitted &&
                                        isNaN(values?.mobile_number) && (
                                            <small className="p-error">
                                                Mobile Number must be in numeric
                                                format
                                            </small>
                                        )}
                                </div>
                            </div>
                            <div className="col-12 md:col-4 lg:col content pr-4 pl-4">
                                <div>
                                    <span className="p-float-label">
                                        <InputText
                                            className="vw-input"
                                            autoComplete="off"
                                            required={true}
                                            name="email"
                                            value={values?.email}
                                            onChange={handleInputChange}
                                        />
                                        <label>Email Address *</label>
                                    </span>
                                    {formSubmitted && !values?.email && (
                                        <small className="p-error">
                                            Please enter email address.
                                        </small>
                                    )}
                                    {formSubmitted &&
                                        values?.email &&
                                        !validateEmail(values?.email) && (
                                            <small className="p-error">
                                                Invalid Email Address
                                            </small>
                                        )}
                                </div>
                            </div>
                            <div className="col-12 md:col-4 lg:col content pl-4 pr-4">
                                <div>
                                    <span className="p-float-label">
                                        <Password
                                            toggleMask
                                            feedback={false}
                                            type="password"
                                            className="vw-input-password"
                                            autoComplete="off"
                                            required={false}
                                            name="password"
                                            value={
                                                !!values?.password
                                                    ? values?.password
                                                    : ""
                                            }
                                            onChange={handleInputChange}
                                        />
                                        <label>Update Password</label>
                                    </span>
                                    {/* {formSubmitted && !values?.password && (
                                            <small className="p-error">
                                                Please enter password
                                            </small>
                                        )} */}
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12 md:col-4 lg:col content pl-4 pr-4">
                                <div>
                                    <span className="p-float-label">
                                        <Dropdown
                                            className="vw-dropdown"
                                            value={values?.nationality}
                                            options={["Indian", "Others"]}
                                            required={false}
                                            name="nationality"
                                            onChange={handleInputChange}
                                            // placeholder="Select a Country"
                                        />
                                        <label>Nationality *</label>
                                    </span>
                                    {formSubmitted && !values?.nationality && (
                                        <small className="p-error">
                                            Please select nationality
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 md:col-4 lg:col content pl-4 pr-4">
                                <div>
                                    <span className="p-float-label">
                                        <Dropdown
                                            className="vw-dropdown"
                                            value={values?.category_name}
                                            options={categories}
                                            required={false}
                                            name="category_name"
                                            onChange={handleInputChange}
                                            optionLabel="name"
                                            optionValue="name"
                                            // placeholder="Select a Country"
                                        />
                                        <label>Category *</label>
                                    </span>
                                    {formSubmitted &&
                                        !values?.category_name && (
                                            <small className="p-error">
                                                Please select category
                                            </small>
                                        )}
                                </div>
                            </div>
                            <div className="col-12 md:col-4 lg:col content pr-4 pl-4">
                                <div>
                                    <span className="p-float-label vw-calendar">
                                        <Calendar
                                            style={{ width: "100%" }}
                                            inputId="date_of_birth"
                                            value={
                                                new Date(values?.date_of_birth)
                                            }
                                            name="date_of_birth"
                                            onChange={handleInputChange}
                                            dateFormat="dd-mm-yy"
                                            showIcon
                                            monthNavigator
                                            yearNavigator
                                            yearRange={`1970:${moment().format(
                                                "YYYY"
                                            )}`}
                                        />

                                        <label htmlFor="birth_date">
                                            Date Of Birth *
                                        </label>
                                    </span>
                                    {formSubmitted &&
                                        !values?.date_of_birth && (
                                            <small className="p-error">
                                                Please enter Date Of Birth
                                            </small>
                                        )}
                                </div>
                            </div>
                            {/* <div className="col-12 md:col-4 lg:col content pr-4 pl-4">
                                    <FileUpload
                                        ref={dateOfBirthRef}
                                        mode="basic"
                                        name="dateOfBirthImage"
                                        // url="/api/upload"
                                        removeIcon
                                        // className="fileUpload"
                                        customUpload
                                        onSelect={(event) =>
                                            myUploader(event, "dateOfBirthImage")
                                        }
                                        // auto
                                        chooseLabel="Choose Date of Birth"
                                        accept="pdf/*"
                                        // uploadHandler={(event) =>
                                        //     myUploader(event, "dateOfBirthImage")
                                        // }
                                    />
                                    {formSubmitted &&
                                        !values?.dateOfBirthImage?.name && (
                                            <small className="p-error">
                                                Please select Date of Birth Proof
                                            </small>
                                        )}
                                     <Button
                                        type="button"
                                        label="Clear"
                                        onClick={clearImage}
                                        className="p-button-raised p-button-lg mr-4"
                                    />
                                </div> */}
                        </div>
                        <div className="grid">
                            <div className="col-12 md:col-4 lg:col content pr-4 pl-4">
                                <div>
                                    <span className="p-float-label">
                                        <InputText
                                            className="vw-input"
                                            autoComplete="off"
                                            required={true}
                                            name="nearest_railway_station"
                                            value={
                                                values?.nearest_railway_station
                                            }
                                            onChange={handleInputChange}
                                        />
                                        <label>Nearest Railway Station *</label>
                                    </span>
                                    {formSubmitted &&
                                        !values?.nearest_railway_station && (
                                            <small className="p-error">
                                                This field is required
                                            </small>
                                        )}
                                </div>
                            </div>
                            <div className="col-12 md:col-4 lg:col content pr-4 pl-4">
                                <div>
                                    <span className="p-float-label">
                                        <InputText
                                            className="vw-input"
                                            autoComplete="off"
                                            required={true}
                                            name="street1"
                                            value={values?.street1}
                                            onChange={handleInputChange}
                                        />
                                        <label>Street 1 *</label>
                                    </span>
                                    {formSubmitted && !values?.street1 && (
                                        <small className="p-error">
                                            Please enter Street 1
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 md:col-4 lg:col content pr-4 pl-4">
                                <div>
                                    <span className="p-float-label">
                                        <InputText
                                            className="vw-input"
                                            autoComplete="off"
                                            required={false}
                                            name="street2"
                                            value={values?.street2}
                                            onChange={handleInputChange}
                                        />
                                        <label>Street 2</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="col-12 md:col-4 lg:col content pr-4 pl-4">
                                <div>
                                    <span className="p-float-label">
                                        <InputText
                                            className="vw-input"
                                            autoComplete="off"
                                            required={true}
                                            name="city"
                                            value={values?.city}
                                            onChange={handleInputChange}
                                        />
                                        <label>City *</label>
                                    </span>
                                    {formSubmitted && !values?.city && (
                                        <small className="p-error">
                                            Please enter city
                                        </small>
                                    )}
                                </div>
                            </div>
                            <div className="col-12 md:col-4 lg:col content pl-4 pr-4">
                                <div>
                                    <span className="p-float-label">
                                        <Dropdown
                                            className="vw-dropdown"
                                            value={values?.state}
                                            options={states}
                                            required={false}
                                            name="state"
                                            onChange={handleInputChange}
                                            optionLabel="name"
                                            optionValue="name"
                                            filter
                                            // placeholder="Select a Country"
                                        />
                                        {/* <InputText
                                            className="vw-input"
                                            autoComplete="off"
                                            required={true}
                                            name="state"
                                            value={values?.state}
                                            onChange={handleInputChange}
                                        /> */}
                                        <label>State *</label>
                                    </span>
                                    {formSubmitted && !values?.state && (
                                        <small className="p-error">
                                            Please select state
                                        </small>
                                    )}
                                </div>
                            </div>

                            <div className="col-12 md:col-4 lg:col content pr-4 pl-4">
                                <div>
                                    <span className="p-float-label">
                                        <InputText
                                            className="vw-input"
                                            autoComplete="off"
                                            required={true}
                                            name="postcode"
                                            value={values?.postcode}
                                            onChange={handleInputChange}
                                        />
                                        <label>Post Code *</label>
                                    </span>
                                    {formSubmitted && !values?.postcode && (
                                        <small className="p-error">
                                            Please enter postcode code
                                        </small>
                                    )}
                                    {formSubmitted &&
                                        isNaN(values?.postcode) && (
                                            <small className="p-error">
                                                Post code must be in numeric
                                                format
                                            </small>
                                        )}
                                </div>
                            </div>
                            {/* <div className="col-12 md:col-4 lg:col content pr-4 pl-4">
                                    <FileUpload
                                        mode="basic"
                                        name="addressProofImage"
                                        // url="/api/upload"
                                        // className="fileUpload"
                                        customUpload
                                        // onUpload={(event) =>
                                        //     myUploader(event, "profileImage")
                                        // }
                                        // auto
                                        chooseLabel="Choose Address Proof"
                                        accept="pdf/*"
                                        uploadHandler={(event) =>
                                            myUploader(event, "addressProofImage")
                                        }
                                    />
                                    {formSubmitted &&
                                        !values?.addressProofImage?.name && (
                                            <small className="p-error">
                                                Please select Address Proof
                                            </small>
                                        )}
                                </div> */}
                            {/* <div className="col-12 md:col-4 lg:col content pr-4 pl-4">
                                    <FileUpload
                                        mode="basic"
                                        name="aadharProof"
                                        // url="/api/upload"
                                        // className="fileUpload"
                                        customUpload
                                        // onUpload={(event) =>
                                        //     myUploader(event, "profileImage")
                                        // }
                                        // auto
                                        chooseLabel="Choose Aadhar Proof"
                                        accept="pdf/*"
                                        uploadHandler={(event) =>
                                            myUploader(event, "aadharProof")
                                        }
                                    />
                                    {formSubmitted &&
                                        !values?.dateOfBirthImage?.name && (
                                            <small className="p-error">
                                                Please select Aadhar Proof
                                            </small>
                                        )}
                                </div> */}
                        </div>

                        <div className="grid">
                            <div className="col-12 md:col-4 lg:col content pl-4 pr-4">
                                <div>
                                    <span className="p-float-label">
                                        <Dropdown
                                            className="vw-dropdown"
                                            value={values?.emp_of_govt}
                                            options={yesNoList}
                                            required={false}
                                            name="emp_of_govt"
                                            onChange={handleInputChange}
                                            // placeholder="Select a Country"
                                        />
                                        <label>
                                            Emp of Govt/ Semi Govt/ PSU/ PSE/
                                            Autonoumous Org *
                                        </label>
                                    </span>
                                    {formSubmitted && !values?.emp_of_govt && (
                                        <small className="p-error">
                                            This field is required
                                        </small>
                                    )}
                                </div>
                            </div>

                            <div className="col-12 md:col-4 lg:col content pl-4 pr-4">
                                <div>
                                    <span className="p-float-label">
                                        <InputText
                                            className="vw-input"
                                            autoComplete="off"
                                            required={true}
                                            name="aadhar_number"
                                            value={values?.aadhar_number}
                                            onChange={handleInputChange}
                                        />
                                        <label>Aadhar Number *</label>
                                    </span>
                                    {formSubmitted &&
                                        !values?.aadhar_number && (
                                            <small className="p-error">
                                                Please enter aadhar number
                                            </small>
                                        )}

                                    {formSubmitted &&
                                        values?.aadhar_number &&
                                        values?.aadhar_number.length !== 12 && (
                                            <small className="p-error">
                                                Please enter valid aadhar number
                                            </small>
                                        )}
                                    {formSubmitted &&
                                        values?.aadhar_number &&
                                        isNaN(values?.aadhar_number) && (
                                            <small className="p-error">
                                                Please enter valid aadhar number
                                            </small>
                                        )}
                                </div>
                            </div>
                            <div className="col-12 md:col-4 lg:col content pl-4 pr-4">
                                <div>
                                    <span className="p-float-label">
                                        <InputText
                                            className="vw-input"
                                            autoComplete="off"
                                            required={true}
                                            name="pan_number"
                                            value={values?.pan_number}
                                            onChange={handleInputChange}
                                        />
                                        <label>Pan Number *</label>
                                    </span>
                                    {formSubmitted && !values?.pan_number && (
                                        <small className="p-error">
                                            Please enter pan number
                                        </small>
                                    )}
                                    {formSubmitted &&
                                        values?.pan_number &&
                                        values?.pan_number.length !== 10 && (
                                            <small className="p-error">
                                                Please enter valid pan number
                                            </small>
                                        )}
                                </div>
                            </div>
                            {/* <div className="col-12 md:col-6 lg:col content pl-4 pr-4">
                                    <div>
                                        <span className="p-float-label">
                                            <Dropdown
                                                className="vw-dropdown"
                                                value={values?.country}
                                                options={countries}
                                                required={true}
                                                name="country"
                                                onChange={handleInputChange}
                                                optionLabel="name"
                                                optionValue="name"
                                                // placeholder="Select a Country"
                                            />
                                            <label>Country *</label>
                                        </span>
                                        {formSubmitted && !values?.country && (
                                            <small className="p-error">
                                                Please select country
                                            </small>
                                        )}
                                    </div>
                                </div> */}
                        </div>
                        <div className="grid">
                            <div className="col-12 md:col-6 lg:col content pr-4 pl-4">
                                <div className="flex">
                                    <FileUpload
                                        ref={fileUploadRef}
                                        name="profileImage"
                                        className="fileUpload"
                                        mode="basic"
                                        customUpload
                                        auto
                                        uploadHandler={(event) =>
                                            myUploader(event, "profileImage")
                                        }
                                        // onUpload={(event) =>
                                        //     myUploader(event, "profileImage")
                                        // }
                                        chooseLabel="Choose Profile Image"
                                        accept="image/*"
                                    />
                                    <label className="ml-4 mt-2">
                                        <strong>
                                            {values?.profileImageName}
                                        </strong>
                                    </label>
                                </div>
                                <div>
                                    {values?.profileImagePath && (
                                        <img
                                            className="ml-4"
                                            src={`${process.env.REACT_APP_API_BASE_URL}/fetchImages/${values?.profileImagePath}`}
                                            alt="Profile"
                                            style={{
                                                width: "auto",
                                                height: "100px",
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </Card>
                </form>
            </div>
        </div>
    );
};

export default MyProfile;
