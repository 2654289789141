import React from 'react'

export default function OtherDetails({personalInformation}) {
    return (
        <div className="grid">
            <div className="col-6">
                <div>
                    <label>Reference 1 (with full address and contact numbers)</label>
                </div>
                <div>
                <label>
                        <strong>{`${!personalInformation?.referenceOne || personalInformation?.referenceOne === "null" ? "-" : personalInformation?.referenceOne} `}</strong>
                    </label>
                </div>
            </div>
            <div className="col-6">
                <div>
                    <label>Reference 2 (with full address and contact numbers)</label>
                </div>
                <div>
                <label>
                        <strong>{`${!personalInformation?.referenceTwo || personalInformation?.referenceTwo === "null" ? "-" : personalInformation?.referenceTwo} `}</strong>
                    </label>
                </div>
            </div>
            <div className="col-6">
                <div>
                    <label>If selected minimum time required to join NCRA-TIFR</label>
                </div>
                <div>
                <label>
                        <strong>{`${!personalInformation?.minJoiningTime || personalInformation?.minJoiningTime === "null" ? "-" : personalInformation?.minJoiningTime} `}</strong>
                    </label>
                </div>
            </div>
            <div className="col-6">
                <div>
                    <label>Any Other relevant information / Remarks</label>
                </div>
                <div>
                <label>
                        <strong>{`${!personalInformation?.otherRemarks || personalInformation?.otherRemarks === "null" ? "-" : personalInformation?.otherRemarks} `}</strong>
                    </label>
                </div>
            </div>
            <div className="col-12">
                <div>
                    <label>Brief Description of Job. *</label>
                </div>
                <div>
                <label>
                        <strong>{`${!personalInformation?.briefDescriptionJob || personalInformation?.briefDescriptionJob === "null" ? "-" : personalInformation?.briefDescriptionJob} `}</strong>
                    </label>
                </div>
            </div>

            <div className="col-6">
                <div>
                    <label>Whether you were Offered to join NCRA-TIFR for any Posts & did not join. If Yes, Please give complete details of it</label>
                </div>
                <div>
                <label>
                        <strong>{`${!personalInformation?.offeredJob || personalInformation?.offeredJob === "null" ? "-" : personalInformation?.offeredJob} `}</strong>
                    </label>
                </div>
            </div>
            <div className="col-6">
                <div>
                    <label>Have you been detained in Policy Custody? If Yes, Please give complete details of it</label>
                </div>
                <div>
                <label>
                        <strong>{`${!personalInformation?.policeCustody || personalInformation?.policeCustody === "null" ? "-" : personalInformation?.policeCustody} `}</strong>
                    </label>
                </div>
            </div>
            <div className="col-6">
                <div>
                    <label>Have you been convicted by any Court of Law or not? If Yes, Please give complete details of it</label>
                </div>
                <div>
                <label>
                        <strong>{`${!personalInformation?.convictedCourtLaw || personalInformation?.convictedCourtLaw === "null" ? "-" : personalInformation?.convictedCourtLaw} `}</strong>
                    </label>
                </div>
            </div>
            <div className="col-6">
                <div>
                    <label>Whether any criminal case is pending or contemplated against you in Court of Law? If Yes, Please give complete details of it</label>
                </div>
                <div>
                    <label>
                        <strong>{`${!personalInformation?.criminalCase || personalInformation?.criminalCase === "null" ? "-" : personalInformation?.criminalCase} `}</strong>
                    </label>
                </div>
            </div>
        </div>
    );
}
