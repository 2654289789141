import { useEffect, useState } from "react";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import SuspenseFallback from "../SuspenseFallback/SuspenseFallback";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import ClientHeader from "../partials/ClientHeader";


const DefaultLayout = (props) => {

    const [searchParams] = useSearchParams();
    const clientId = searchParams.get("clientid");
    const [agentValues, setAgentValues] = useState(null);
    const [visible, setVisible] = useState(false);
    const [brokerValues, setBrokerValues] = useState(null);
    const location = useLocation();
    
    

    const handleSidebar = () => {
        setVisible(!visible);
    }
    // useEffect(()=> {
    //     if(clientId && clientId !== "null") {
    //         fetchClientInformation(clientId);
    //     } else if(!clientId) {
    //         setAgentValues(null);
    //         setBrokerValues(null);
            
    //     }
    // }, [clientId])

    const renderHeader = () => {
        if(location.pathname.indexOf('reports') == -1) {
            if(clientId && clientId !== "null") {
                return <ClientHeader agentValues={agentValues} brokerValues={brokerValues} />
            } else {
                return <Header handleSidebar={handleSidebar} />
            }
        }
    }

    // let items = [
    //     {
    //         label: "Home",
    //         command: () => {
    //             window.location.hash = `/`;
    //         },
    //     },
    //     {
    //         label: "My Applications",
    //         command: () => {
    //             window.location.hash = `/my-applications`;
    //         },
    //     },
    //     {
    //         label: "My Profile",
    //         command: () => {
    //             window.location.hash = `/my-profile`;
    //         },
    //     },
    //     {
    //         label: "My Documents",
    //         command: () => {
    //             window.location.hash = `/my-documents`;
    //         },
    //     },
    //     {
    //         label: "My Qualifcation",
    //         command: () => {
    //             window.location.hash = `/my-qualification`;
    //         },
    //     },
    //     {
    //         label: "My Experience",
    //         command: () => {
    //             window.location.hash = `/my-experience`;
    //         },
    //     },
    //     {
    //         label: "Logout",
    //         command: () => {
    //             // dispatch(clearHomePurchaseValues());
    //             sessionStorage.clear();
    //             // navigate("/");
    //         },
    //     },
    // ];

    return (
        <SuspenseFallback>
            <div className="App-container">
                {/* <Sidebar visible={visible} onHide={() => setVisible(false)} position="right" >
                <MegaMenu model={items} orientation="vertical" breakpoint="767px" />

                </Sidebar> */}
                <div className="outer-container">
                    <header className="mb-2 mt-2">
                        {renderHeader()}
                    </header>
                    <main className="container-fluid">
                        <div className={useLocation().pathname.indexOf('reports') == -1 && 'child-outlet'} >
                            <Outlet />
                        </div>
                    </main>
                    <footer>
                        <Footer />
                    </footer>
                </div>
            </div>
        </SuspenseFallback>
    );
};

export default DefaultLayout;
