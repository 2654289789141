import RestClient from "RestClient";
const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const restClient = new RestClient();

export const getQualificationList = async (id, payload) => {
    const result = await restClient.get(`${BASE_URL}/qualifications?user_id=${id}`);
    return result?.data; 
}

export const createQualification = async (payload) => {
    const result = await restClient.post(`${BASE_URL}/qualifications`, payload);
    return result?.data; 
    // return true;
}

export const updateQualifcation = async (id, payload) => {
    const result = await restClient.put(`${BASE_URL}/qualifications/${id}`, payload);
    return result?.data; 
}

export const getSingleQualification = async (id) => {
    const result = await restClient.get(`${BASE_URL}/qualifications/${id}`);
    return result?.data; 
}

export const deleteQualification = async (id) => {
    const result = await restClient.delete(`${BASE_URL}/qualifications/${id}`);
    return result?.data; 
}

export const getDegreeList = async () => {
    const result = await restClient.get(`${BASE_URL}/degrees`);
    return result?.data; 
}