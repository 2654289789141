import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

const ProfessionalExperience = ({isLoading, experienceList}) => {
    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field] ? data[props.field] : "-"}
            </>
        );
    };

    const dateTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field] ? moment(data[props.field]).format('DD-MM-YYYY') : '-'}
            </>
        );
    };

    // const pdfTemplate = (data, header, pathName) => {
    //     return (
    //         <>
    //             <span className="p-column-title">{header}</span>
    //             {data?.fileName ? (
    //                 <a target="_blank" href={`${BASE_URL}/${pathName}/${data?.fileName}`}>
    //                     View File
    //                 </a>
    //             ) : (
    //                 '-'
    //             )}
    //         </>
    //     );
    // };

    return (
        <DataTable
            value={experienceList}
            scrollable
            scrollHeight="600px"
            scrollDirection="both"
            className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
            rows={10}
            dataKey="id"
            paginator={false}
            rowHover
            // globalFilter={globalFilter}
            emptyMessage="No list found."
            loading={isLoading}
            // header={tableHeader}
        >
            <Column
                style={{
                    flexGrow: 1,
                    flexBasis: '50px'
                }}
                field="srNo"
                header="Sr. No."
                sortable
                body={bodyTemplate}
            ></Column>
            <Column
                style={{
                    flexGrow: 1,
                    flexBasis: '100px'
                }}
                field="name_of_organization"
                header="Name of Org"
                sortable
                body={bodyTemplate}
            ></Column>
            <Column
                style={{
                    flexGrow: 1,
                    flexBasis: '100px'
                }}
                field="post"
                header="Post"
                sortable
                body={bodyTemplate}
            ></Column>
            <Column
                style={{
                    flexGrow: 1,
                    flexBasis: '100px'
                }}
                field="start_date"
                header="Start Date"
                sortable
                body={dateTemplate}
            ></Column>
            <Column
                style={{
                    flexGrow: 1,
                    flexBasis: '100px'
                }}
                field="end_date"
                header="End Date"
                sortable
                body={dateTemplate}
            ></Column>
            <Column
                style={{
                    flexGrow: 1,
                    flexBasis: '100px'
                }}
                field="years_of_experience"
                header="Year Of Experience"
                sortable
                body={bodyTemplate}
            ></Column>
            <Column
                style={{
                    flexGrow: 1,
                    flexBasis: '100px'
                }}
                field="pay_scale"
                header="Salary"
                sortable
                body={bodyTemplate}
            ></Column>
            
            {/* <Column
                            style={{ flexGrow: 1, flexBasis: "50px" }}
                            field=""
                            header="Actions"
                            body={actionBodyTemplate}
                        ></Column> */}
        </DataTable>
    );
}


export default ProfessionalExperience;