import { Loading } from "app/components";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
import { Fragment, useEffect, useRef, useState } from "react";
import { bodyTemplate } from "app/utils/datatable.service";
import { dateTemplate } from "app/utils/datatable.service";
import {
    deleteApplication,
    getApplicationList,
} from "./my-application.service";
import { getSessionUserInformation } from "app/utils/utility.service";
import { getUserDocumentList } from "../my-documents/my-document.service";
import { getExperienceList } from "../my-experience/my-experience.service";
import { getQualificationList } from "../my-qualification/my-qualification.service";
import { getSingleUser } from "../my-profile/my-profile.service";
import ProfileView from "./profile-view";
import { Dialog } from "primereact/dialog";

const MyApplicationList = () => {
    const toast = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [list, setList] = useState([]);
    const [rowData, setRowData] = useState(null);

    const [userInformation, setUserInformation] = useState(null);
    const [userDocuments, setUserDocuments] = useState([]);
    const [userQualification, setUserQualification] = useState([]);
    const [userExperience, setUserExperience] = useState([]);
    const sessionValues = getSessionUserInformation();

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete?")) {
            const deletedRecord = await deleteApplication(id);
            if (deletedRecord) {
                fetchList();
                alert("Record deleted successfully");
                return;
            } else {
                alert("Something went wrong, please try again later");
                return;
            }
        }
    };

    const handleShowPopup = (data) => {
        setRowData(data || null);
        setShowPopup(!showPopup);
    };

    const fetchUserInfo = async () => {
        // const userInfo = await
        setIsLoading(true);
        const userInfo = await getSingleUser(sessionValues?.id);
        setIsLoading(false);
        if (userInfo) {
            setUserInformation(userInfo);
            return;
        }
        setUserInformation(null);
    };

    const fetchUserDocuments = async () => {
        setIsLoading(true);
        let list = await getUserDocumentList(sessionValues?.id);
        setIsLoading(false);
        if (list.length > 0) {
            list.map((li, index) => (li.srNo = index + 1));
            setUserDocuments(list);
            return;
        }
        setUserDocuments([]);
    };

    const fetchUserExperience = async () => {
        setIsLoading(true);
        const list = await getExperienceList(sessionValues?.id);
        setIsLoading(false);
        if (list.length > 0) {
            list.map((li, index) => (li.srNo = index + 1));
            setUserExperience(list);
        } else {
            setUserExperience([]);
        }
    };

    const fetchUserQualifications = async () => {
        setIsLoading(true);
        const list = await getQualificationList(sessionValues?.id);
        setIsLoading(false);
        if (list.length > 0) {
            list.map((li, index) => (li.srNo = index + 1));
            setUserQualification(list);
        } else {
            setUserQualification([]);
        }
    };

    useEffect(() => {
        if (!!sessionValues?.id) {
            fetchUserInfo();
            fetchUserDocuments();
            fetchUserExperience();
            fetchUserQualifications();
        }
    }, [sessionValues?.id]);

    const actionBodyTemplate = (data, props) => {
        return (
            <>
                <Button
                    onClick={() => handleShowPopup(data)}
                    className="p-button p-button-primary mr-2"
                >
                    View
                </Button>
            </>
        );
    };


    const fetchList = async () => {
        setIsLoading(true);

        let list = await getApplicationList(sessionValues?.id);
        setIsLoading(false);
        if (list.length > 0) {
            list.reverse();
            list.map((li, index) => (li.srNo = index + 1));
            setList(list);
        } else {
            setList([]);
        }
        return;
    };

    useEffect(() => {
        fetchList();
    }, []);

    return (
        <Fragment>
            <div className="card">
                <Card className="mb-4" title="Applied Posts">
                    <div className="grid table-demo">
                        <Toast ref={toast} className="ToastMessage" />
                        {isLoading && (
                            <div className="spinner inner-spinner">
                                <Loading />
                            </div>
                        )}
                        <div className="col-12 lg:col-12 md:col-12">
                            <div className="card">
                                <DataTable
                                    value={list}
                                    scrollable
                                    scrollHeight="600px"
                                    scrollDirection="both"
                                    className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                    rows={10}
                                    dataKey="id"
                                    paginator
                                    rowHover
                                    // globalFilter={globalFilter}
                                    emptyMessage="No list found."
                                    loading={isLoading}
                                    // header={tableHeader}
                                >
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "50px",
                                        }}
                                        field="srNo"
                                        header="Sr. No."
                                        sortable
                                        body={bodyTemplate}
                                    ></Column>

                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="vacancy.vacancy_name"
                                        header="Post name"
                                        sortable
                                        // body={bodyTemplate}
                                    ></Column>

                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="applied_on"
                                        header="Applied on"
                                        sortable
                                        body={dateTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="form_submitted_on"
                                        header="Form Submitted on"
                                        sortable
                                        body={dateTemplate}
                                    ></Column>

                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "100px",
                                        }}
                                        field="status"
                                        header="Status"
                                        body={bodyTemplate}
                                    ></Column>
                                    <Column
                                        style={{
                                            flexGrow: 1,
                                            flexBasis: "50px",
                                        }}
                                        field=""
                                        header="Actions"
                                        body={actionBodyTemplate}
                                    ></Column>
                                </DataTable>
                            </div>
                        </div>
                        {/* {displayConfirmation && (
                <ConfirmationDialogBox
                    handleCancelConfirmation={handleCancelConfirmation}
                    handleConfirmation={handleConfirmation}
                    displayConfirmation={displayConfirmation}
                />
            )} */}
                    </div>
                </Card>
            </div>
            {showPopup && (
                <Dialog
                    header={`Application `}
                    visible={showPopup}
                    onHide={() => handleShowPopup()}
                    breakpoints={{ "960px": "75vw" }}
                    style={{ width: "75vw" }}
                    className="AnnualExpenseModal"
                    position="top"
                    closeOnEscape={false}
                    draggable={false}
                >
                    {" "}
                    <ProfileView
                        applicationInformation={rowData}
                        personalInformation={userInformation}
                        experienceList={userExperience}
                        qualificationList={userQualification}
                        documentList={userDocuments}
                        isLoading={isLoading}
                        userInputsList={{
                            userDocuments,
                            userExperience,
                            userQualification,
                            userInformation,
                        }}
                    />
                </Dialog>
            )}
        </Fragment>
    );
};

export default MyApplicationList;
