import moment from "moment";

export const bodyTemplate = (data, props) => {
    return (
        <>
            <span className="p-column-title">{props.header}</span>
            {data[props.field]}
        </>
    );
};

export const dateTemplate = (data, props) => {
    return (
        <>
            <span className="p-column-title">{props.header}</span>
            {data[props.field]
                ? moment(data[props.field]).format("DD-MM-YYYY")
                : "-"}
        </>
    );
};


export const pdfTemplate = (data, header, pathName) => {
    return (
        <>
            <span className="p-column-title">{header}</span>
            {data?.fileName
                ? <a target="_blank" href={`${process.env.REACT_APP_API_BASE_URL}/${pathName}/${data?.fileName}`}>View File</a>
                : "-"}
        </>
    );
};