import React, { lazy } from "react";
import Loadable from "../../components/Loadable/Loadable";
import MyQualificationList from "./my-qualification/MyQualificationList";
import MyExperienceList from "./my-experience/MyExperienceList";
import MyApplicationList from "./my-applications/MyApplicationList";
import MyDocumentList from "./my-documents/MyDocumentList";
import Stepper from "./stepper/Stepper";

const Step1 = Loadable(lazy(() => import("./login/Step1")));
const Register = Loadable(lazy(() => import("./register/Register")));
const ForgotPassword = Loadable(lazy(() => import("./forgot-password/ForgotPassword")));
const ResetPassword = Loadable(lazy(() => import("./reset-password/ResetPassword")));
const MyProfile = Loadable(lazy(() => import("./my-profile/MyProfile")));


const sessionRoutes = [
    {
        path: "/session/signup",
        element: <Register />,
    },
    {
        path: "/session/signin/step1",
        element: <Step1 />,
    },
    {
        path: "/session/forgot-password",
        element: <ForgotPassword />,
    },
    {
        path:"/reset-password",
        element: <ResetPassword />
    },
    {
        path:"/my-profile",
        element: <MyProfile />
    },
    {
        path:"/my-qualification",
        element: <MyQualificationList />
    },
    {
        path:"/my-experience",
        element: <MyExperienceList />
    },
    {
        path:"/my-applications",
        element: <MyApplicationList />
    },
    {
        path:"/my-documents",
        element: <MyDocumentList />
    },

    {
        path:"/my-stepper",
        element: <Stepper />
    },
];

export default sessionRoutes;
