import moment from "moment";

const PersonalInformation = ({personalInformation}) => {
    return (
        <div className="grid">
            <div className="col-4">
                <div className="grid">
                    <div className="col-4">
                        <label>Name</label>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-8">
                        <label>
                            <strong>{`${personalInformation?.firstname} ${personalInformation?.middlename} ${personalInformation?.lastname}`}</strong>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="grid">
                    <div className="col-4">
                        <label>Email</label>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-8">
                        <label>
                            <strong>{`${personalInformation?.email}`}</strong>
                        </label>
                    </div>
                </div>
            </div>

            <div className="col-4">
                <div className="grid">
                    <div className="col-4">
                        <label>Mobile Number</label>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-8">
                        <label>
                            <strong>{`${personalInformation?.mobile_number}`}</strong>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="grid">
                    <div className="col-4">
                        <label>Category</label>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-8">
                        <label>
                            <strong>{`${personalInformation?.category_name}`}</strong>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="grid">
                    <div className="col-4">
                        <label>Date of Birth</label>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-8">
                        <label>
                            <strong>{`${moment(personalInformation?.date_of_birth).format('DD-MM-YYYY')}`}</strong>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="grid">
                    <div className="col-4">
                        <label>Gender</label>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-8">
                        <label>
                            <strong>{`${personalInformation?.gender}`}</strong>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="grid">
                    <div className="col-4">
                        <label>Nationality</label>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-8">
                        <label>
                            <strong>{`${personalInformation?.nationality}`}</strong>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="grid">
                    <div className="col-4">
                        <label>Marital Status</label>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-8">
                        <label>
                            <strong>{`${personalInformation?.marital_status}`}</strong>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="grid">
                    <div className="col-4">
                        <label>Ex-Serviceman</label>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-8">
                        <label>
                            <strong>{`${personalInformation?.ex_serviceman}`}</strong>
                        </label>
                    </div>
                </div>
            </div>
           
            <div className="col-4">
                <div className="grid">
                    <div className="col-4">
                        <label>Street 1</label>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-8">
                        <label>
                            <strong>{`${personalInformation?.street1}`}</strong>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="grid">
                    <div className="col-4">
                        <label>Street 2</label>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-8">
                        <label>
                            <strong>{`${personalInformation?.street2}`}</strong>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="grid">
                    <div className="col-4">
                        <label>City</label>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-8">
                        <label>
                            <strong>{`${personalInformation?.city}`}</strong>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="grid">
                    <div className="col-4">
                        <label>State</label>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-8">
                        <label>
                            <strong>{`${personalInformation?.state}`}</strong>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="grid">
                    <div className="col-4">
                        <label>Country</label>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-8">
                        <label>
                            <strong>{`India`}</strong>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-4">
                <div className="grid">
                    <div className="col-4">
                        <label>Postcode</label>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-8">
                        <label>
                            <strong>{`${personalInformation?.postcode}`}</strong>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="grid">
                    <div className="col-4">
                        <label>Emp of Govt/ Semi Govt/ PSU/ PSE/Autonoumous Org</label>
                    </div>
                    <div className="col-12 lg:col-6 xl:col-8">
                        <label>
                            <strong>{`${personalInformation?.emp_of_govt}`}</strong>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PersonalInformation;